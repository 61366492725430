/**
 * The TokenSearch container
 */
import React, { useEffect } from "react";
import { useSsrEffect } from "@issr/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Input, Row, Col } from "reactstrap";
import { listTokens, setListPage } from "../../redux/tokens";
import TokenList from "../Token/TokenList";
import PageHead from "../PageHead";
import PaginationSection from "../PaginationSection";
import ScrollToTop from "../Utils/Scroll/ScrollToTop";
import { REACT_APP_PROJECT_ID } from "../../utils/env";

/**
 * TokenSearch container, the landing page for logged in users
 */
const TokenSearch = (props, context) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  // const listTotalCount = useSelector((state) => state.tokens.listTotalCount);
  const history = useHistory();
  const [search, setSearch] = useState(undefined);
  const totalPages = useSelector((state) => state?.tokens?.totalPages);
  const currentPage = useSelector((state) => state?.tokens?.listPage);
  const searchParams = useLocation().search;
  const pageNum = new URLSearchParams(searchParams).get("page");
  const itemNum = new URLSearchParams(searchParams).get("items") || "9";
  let { url } = useRouteMatch();

  const setPage = (page) => {
    history.push(url + "?page=" + page + "&items=" + itemNum);
  };

  let searchTimer;

  const handleSearchChange = (e) => {
    e.preventDefault();

    if (searchTimer) clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      setPage(1);
      dispatch(setListPage(1));
      if (e.target.value === "") setSearch(undefined);
      else {
        setSearch(e.target.value);
      }
    }, 1000);
  };

  const dispatchApi = async () => {
    // We always want to update the list of tokens
    dispatch(
      listTokens({
        projectId: REACT_APP_PROJECT_ID,
        account: account,
        sorting: {},
        filters: { search },
        pageNum: pageNum,
        itemNum: itemNum,
      })
    );
  };

  useSsrEffect(dispatchApi);
  useEffect(dispatchApi, [account, dispatch, search, pageNum, itemNum]);

  return (
    <div className="TokenSearch">
      <ScrollToTop />
      <Row className="mb-3 mb-sm-5 mt-3 mt-md-5 d-flex justify-content-between align-items-center">
        <Col xs={12} lg={{ size: 8, order: 1 }} className="mb-2">
          <div className="d-flex align-items-center">
            <PageHead title={context.t("Collection")} />
          </div>
        </Col>
        <Col xs={12} lg={{ size: 4, order: 2 }} className="mb-2">
          <div className="mb-3 d-flex justify-content-end flex-wrap flex-sm-nowrap">
            <Input
              onChange={handleSearchChange}
              minLength="42"
              maxLength="42"
              type="text"
              className="me-0 me-sm-3 mb-3 mb-sm-0 bg-transparent"
              placeholder={context.t("Type Something")}
            />
          </div>
        </Col>
      </Row>
      <TokenList collection />
      <PaginationSection
        currentPage={currentPage}
        totalPages={totalPages}
        basePath={url}
        itemNum={itemNum}
      />
    </div>
  );
};

/**  define proptype for the 'translation' function  */
TokenSearch.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenSearch;
