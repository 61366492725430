/**
 * The PageHead container
 */
import React from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import { push, goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { MdArrowBack } from "react-icons/md";

/**
 * PageHead component used as app default landing page
 *
 */

const PageHead = (props, context) => {
  const { title, backTarget } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    if (history.length > 1) {
      dispatch(goBack());
    } else {
      dispatch(push(`${backTarget}`));
    }
  };

  return (
    <div className="d-flex">
      {backTarget && (
        <div>
          <Button
            onClick={handleBack}
            color="primary"
            outline
            title={context.t("Back")}
            className="me-3"
          >
            <MdArrowBack />
          </Button>
        </div>
      )}
      <h1 className="display-5 text-dark fw-bold my-0 py-0">{title}</h1>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
PageHead.contextTypes = {
  t: PropTypes.func,
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  backTarget: PropTypes.string,
};

PageHead.defaultProps = {
  backTarget: null,
};

/** export the component as redux connected component */
export default withRouter(PageHead);
