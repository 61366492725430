import React from "react";
import PropTypes from "prop-types";
//import { GrHomeRounded } from "react-icons/gr";
import {
  HiOutlineHome,
  HiHome,
  HiOutlineTicket,
  HiTicket,
  HiOutlineViewGrid,
  HiViewGrid,
  HiOutlineCalendar,
  HiCalendar,
} from "react-icons/hi";
import { NavLink, useRouteMatch } from "react-router-dom";

const ArcticNavbar = (props, context) => {
  let { url } = useRouteMatch();

  return (
    <div className="arcticnavbar d-flex flex-wrap text-md-end text-sm-start justify-content-md-end justify-content-sm-start mt-3 mt-md-0">
      <NavLink
        to="/"
        exact
        className="mb-3 me-md-5 me-3 text-decoration-none d-flex align-items-center arcticnavbar__navlink"
      >
        {url === "/" ? (
          <HiHome className="me-2" size={18} />
        ) : (
          <HiOutlineHome className="me-2" size={18} />
        )}
        <span>{context.t("Home")}</span>
      </NavLink>
      <NavLink
        to="/tokens/my"
        className="mb-3 me-md-5 me-3 text-decoration-none d-flex align-items-center arcticnavbar__navlink"
        isActive={(match, location) => {
          return match ? true : location.pathname.includes("/tokens/");
        }}
      >
        {url.includes("/tokens") ? (
          <HiViewGrid className="me-2" size={18} />
        ) : (
          <HiOutlineViewGrid className="me-2" size={18} />
        )}
        <span>{context.t("NFTs")}</span>
      </NavLink>
      <NavLink
        to="/events"
        className="mb-3 me-md-5 me-3 text-decoration-none d-flex align-items-center arcticnavbar__navlink"
      >
        {url === "/events" ? (
          <HiCalendar className="me-2" size={18} />
        ) : (
          <HiOutlineCalendar className="me-2" size={18} />
        )}
        <span>{context.t("Events")}</span>
      </NavLink>
      <NavLink
        to="/votings"
        className="mb-3 text-decoration-none d-flex align-items-center arcticnavbar__navlink"
      >
        {url === "/votings" ? (
          <HiTicket className="me-2" size={18} />
        ) : (
          <HiOutlineTicket className="me-2" size={18} />
        )}
        <span>{context.t("Voting")}</span>
      </NavLink>
    </div>
  );
};

ArcticNavbar.contextTypes = {
  t: PropTypes.func,
};

export default ArcticNavbar;
