/**
 * The Home container
 */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";
import { getTokenAttributes, getTokenTraitValues } from "../../../redux/tokens";
// import { listUnlockBoundariesForUnlockable } from "../../../redux/unlockBoundaries";
/**
 * Home container used as app default landing page
 */
const TokenCard = (props, context) => {
  const { tokenId, collection } = props;
  const tokenData = useSelector((state) => getTokenAttributes(state, tokenId));
  const { name, thumbnail } = tokenData;
  const tokenTraitValues = useSelector((state) =>
    getTokenTraitValues(state, tokenId)
  );

  const collectionTotalAmount = 3000; // ! TODO how can we get this from contract?
  const bottleTrait = tokenTraitValues?.Bottle;
  const yearTrait = tokenTraitValues?.Year;
  const purchased =
    (tokenTraitValues?.Redeemed && tokenTraitValues?.Redeemed === "true") ||
    false;

  const renderDefault = () => (
    <>
      <CardBody className="d-flex flex-column align-items-center position-relative">
        {purchased && (
          <Badge color="primary position-absolute top-0 end-0 mt-2 me-2 px-3 py-2 fs-9">
            {context.t("Purchased")}
          </Badge>
        )}
        {thumbnail ? (
          <div className="tokencard__imagecontainer ratio ratio-1x1 flex-shrink-0">
            <div className="rounded-circle border border-muted overflow-hidden">
              <img
                src={thumbnail}
                alt={name}
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        ) : (
          <div className="tokencard__imagecontainer bg-primary ratio ratio-1x1 rounded-circle rounded-circle border border-muted flex-shrink-0" />
        )}
        <CardTitle className="h4 mb-1">
          <b> {tokenData?.name?.split("#")[0]}</b>
        </CardTitle>
        <p className="text-muted small">{context.t("Arctic Blue")}</p>
        <div className="w-100 d-flex mb-3 p-2">
          <div className="w-50 text-center border-end border-muted border-1">
            <p className="mb-1 fs-9 text-muted">{context.t("Number")}</p>
            <p className="mb-0 fw-bold">
              {bottleTrait}/{collectionTotalAmount}
            </p>
          </div>
          <div className="w-50 text-center border-start border-muted border-1">
            <p className="mb-1 fs-9 text-muted">{context.t("Year")}</p>
            <p className="mb-0 fw-bold">{yearTrait}</p>
          </div>
        </div>
        <Link
          to={`/tokens/${tokenData?.contract_address}/${tokenData?.identifier}`}
          className="btn btn-primary py-2 w-100 stretched-link"
        >
          {context.t("View NFT")}
        </Link>
      </CardBody>
    </>
  );

  const renderCollectionCard = () => (
    <>
      <CardBody className="d-flex align-items-center py-4 mx-md-3">
        {thumbnail ? (
          <div className="tokencard__imagecontainer ratio ratio-1x1 flex-shrink-0">
            <div className="rounded-circle border border-muted overflow-hidden">
              <img
                src={thumbnail}
                alt={name}
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        ) : (
          <div className="tokencard__imagecontainer bg-primary ratio ratio-1x1 rounded-circle rounded-circle border border-muted" />
        )}
        <div className="ms-3">
          <p className="mb-1 fs-7 text-muted">{context.t("Name")}</p>
          <Link
            to={`/tokens/${tokenData?.contract_address}/${tokenData?.identifier}`}
            className="text-decoration-none stretched-link text-body fs-5 fw-bold"
          >
            {tokenData?.name?.split("#")[0]}
          </Link>
        </div>
        <div className="text-end ms-auto">
          <p className="mb-1 fs-7 text-muted">{context.t("Number")}</p>
          <p className="mb-0 fs-5 fw-bold">
            {bottleTrait}/{collectionTotalAmount}
          </p>
        </div>
      </CardBody>
    </>
  );

  return (
    <Card
      className={`tokencard font-alt mb-3 bg-white shadow-sm border border-muted ${
        !collection ? "tokencard--default" : "tokencard--collection"
      }`}
    >
      {!collection ? renderDefault() : renderCollectionCard()}
    </Card>
  );
};

TokenCard.propTypes = {
  tokenId: PropTypes.string.isRequired,
  collection: PropTypes.bool,
};

TokenCard.defaultProps = {
  collection: false,
};

/**  define proptype for the 'translation' function  */
TokenCard.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenCard;
