/**
 * The Home container
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardTitle,
  Col,
  Container,
  Row,
  CardBody,
  Badge,
} from "reactstrap";
import { HiTicket, HiViewGrid, HiCalendar } from "react-icons/hi";
import Page from "../components/Page";
import ScrollToTop from "../components/Utils/Scroll/ScrollToTop";
import ArcticNavbar from "../components/ArcticNavbar";
import PageHead from "../components/PageHead";
import TokenSearch from "../components/TokenSearch/TokenSearch";
import { useWeb3React } from "@web3-react/core";
import { getWalletAttributes } from "../redux/wallets";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

/**
 * Home container, the landing page for logged in users
 */
const Home = (props, context) => {
  const { account } = useWeb3React();
  const walletData = useSelector((state) =>
    getWalletAttributes(state, account)
  );

  return (
    <Page id="Home" title="Home" noCrawl>
      <ScrollToTop />
      <Container>
        <Row className="mb-3 mb-sm-5 mt-3 mt-md-5 d-flex justify-content-between align-items-center">
          <Col xs={12} lg={{ size: 8, order: 2 }} className="mb-3">
            <ArcticNavbar />
          </Col>
          <Col xs={12} lg={{ size: 4, order: 1 }} className="mb-2">
            <div className="d-flex align-items-center">
              <PageHead title={context.t("Home")} />
              {!walletData?.current_wallet_is_holder && (
                <Badge className="bg-gradient ms-5 py-2 px-4 rounded-1">
                  {context.t("Non-Holder")}
                </Badge>
              )}

              {walletData?.current_wallet_is_holder && (
                <Badge className="bg-gradient ms-5 py-2 px-4 rounded-1">
                  {context.t("Holder")}
                </Badge>
              )}
            </div>
          </Col>
        </Row>
        {/* <div className="pb-3">
            <p className="mb-5">
              {context.t(
                "This is where you find your unique Legacy NFTs, whether redeemed or in our care, yet to be enjoyed."
              )}
              <br />
              {context.t("Click on an individual event to learn more.")}
            </p>
          </div> */}
        <Row className="mb-5 justify-content-center">
          <Col md={3} className="mb-3">
            <Card className="h-100 shadow border-0 bg-gradient text-white">
              {!walletData?.current_wallet_is_holder ? (
                <CardBody>
                  <CardTitle className="mb-3 fw-bold">
                    {context.t("Non-Holder Home")}
                  </CardTitle>
                  <p className="small">
                    {context.t(
                      "Acquire your first Legacy NFT to share the Spirit."
                    )}
                  </p>
                </CardBody>
              ) : (
                <CardBody>
                  <CardTitle className="mb-3 fw-bold">
                    {context.t("Non-Holder Home")}
                  </CardTitle>
                  <p className="small">
                    {context.t(
                      "The home of the Legacy. The Spirit is better when shared."
                    )}
                  </p>
                </CardBody>
              )}
            </Card>
          </Col>

          <Col md={3} className="mb-3">
            <Card className="h-100 bg-white border-0 shadow">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <CardTitle className="mb-3 fw-bold">
                    {context.t("NFT's")}
                  </CardTitle>
                  <div className="text-primary">
                    <HiViewGrid size={20} />
                  </div>
                </div>
                <p className="small">
                  {context.t(
                    "The home for your collection of unique Legacy NFTs."
                  )}
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={3} className="mb-3">
            <Card className="h-100 bg-white border-0 shadow">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <CardTitle className="mb-3 fw-bold">
                    {context.t("Events")}
                  </CardTitle>
                  <div className="text-primary">
                    <HiCalendar size={20} />
                  </div>
                </div>
                <p className="small">
                  {context.t(
                    "Upcoming events exclusively welcoming Legacy NFT holders."
                  )}
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={3} className="mb-3">
            <Card className="h-100 bg-white border-0 shadow">
              <CardBody>
                <div className="d-flex justify-content-between">
                  <CardTitle className="mb-3 fw-bold">
                    {context.t("Voting")}
                  </CardTitle>
                  <div className="text-primary">
                    <HiTicket size={20} />
                  </div>
                </div>
                <p className="small">
                  {context.t(
                    "Active polls about community affairs, open for Legacy holders."
                  )}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {walletData?.current_wallet_is_holder ? (
          <TokenSearch />
        ) : (
          <Row className="mt-2 mb-5">
            <Col xs={12} md={12} lg={7} className="mb-5 mb-lg-0">
              <Card className="border rounded text-align-justify bg-transparent h-100">
                <CardBody className="d-flex flex-column p-5">
                  <h1 className="text-dark mb-4">
                    {context.t("Join the community")}
                  </h1>
                  <hr className="mb-5" />
                  <p className="fw-light fs-7 home__nonholdertext">
                    {context.t(
                      "Behind each Legacy NFT, there is an individual with a taste for the good things in life. Exquisite food and beverages. A moment well spent in likeminded company. The beautiful arctic nature with its berries and botanicals unlike any other. These individuals are the building blocks of the Legacy community. A group with which to share the Spirit and explore the beginnings of Arctic Blue Beverages’ revolutionary journey in the digital space."
                    )}
                  </p>
                  <Row className="mt-5">
                    <Col xs={12} md={6} xl={4}>
                      <Link to={`/purchase`} className="btn btn-primary w-100">
                        {context.t("Purchase")}
                      </Link>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      className="d-flex align-items-center mt-3 mt-md-0"
                    >
                      <span className="text-muted fs-7">Price/NFT</span>
                      <span className="ms-3 text-dark fw-bold">1500 USD</span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={5}>
              <div className="h-100">
                <ReactPlayer
                  width="100%"
                  height="auto"
                  url={`https://metadata.bowline.app/0x443be7eeed658d031ea29c9f500f7b4715ee547b/bottles/A/A0001.mp4`}
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload noplaybackrate",
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                  playing
                  loop
                />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
Home.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default Home;
