/**
 * The Home container
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

/**
 * Home container used as app default landing page
 *
 */

const PaginationSection = (props) => {
  const { totalPages, currentPage, basePath, itemNum } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const urlParams = new URLSearchParams(history.location.search);

  const handlePagination = (page, items = itemNum) => {
    urlParams.set("page", page);
    urlParams.set("items", items);
    history.replace(basePath + "?" + urlParams.toString());
  };

  const changeItemLimit = (selectedItemAmount) => {
    return handlePagination(1, selectedItemAmount);
  };
  const itemAmounts = [20, 40, 60, 80, 100];
  let pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i > currentPage - 5 && i < currentPage + 5) {
      pageItems.push(
        <PaginationItem key={i}>
          <PaginationLink
            key={i}
            onClick={() => handlePagination(i)}
            className="bg-light text-primary border"
          >
            {currentPage !== i && <>{i}</>}
            {currentPage === i && <strong>{i}</strong>}
          </PaginationLink>
        </PaginationItem>
      );
    }
  }

  return (
    <Pagination
      size="sm"
      aria-label="Page navigation example"
      listClassName="justify-content-center"
    >
      <Dropdown
        isOpen={open}
        toggle={toggle}
        className="me-3 bg-light textdark"
        size="sm"
      >
        <DropdownToggle caret className="bg-light text-dark border">
          {itemNum}
        </DropdownToggle>
        <DropdownMenu className="bg-light text-dark">
          {itemAmounts.map((item, index) => (
            <DropdownItem
              key={index}
              onClick={() => changeItemLimit(item)}
              className="bg-light text-dark"
              disabled={item === itemNum}
            >
              {" "}
              {item}{" "}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <PaginationItem>
        <PaginationLink
          onClick={() => handlePagination(1)}
          className="bg-light text-dark rounded-start border"
        >
          {" "}
          «
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          disabled={currentPage === 1}
          previous
          onClick={() => handlePagination(currentPage - 1)}
          className="bg-light text-dark border"
        ></PaginationLink>
      </PaginationItem>
      {pageItems}
      <PaginationItem>
        <PaginationLink
          disabled={currentPage === totalPages}
          next
          onClick={() => handlePagination(currentPage + 1)}
          className="bg-light text-dark border"
        ></PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          onClick={() => handlePagination(totalPages)}
          className="bg-light text-dark rounded-end border"
        >
          {" "}
          »
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

/**  define proptype for the 'translation' function  */
PaginationSection.contextTypes = {
  t: PropTypes.func,
};

PaginationSection.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  basePath: PropTypes.string.isRequired,
  itemNum: PropTypes.string,
};

PaginationSection.defaultProps = {
  totalPages: 1,
  currentPage: 1,
  itemNum: "20",
};

/** export the component as redux connected component */
export default withRouter(PaginationSection);
