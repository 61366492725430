/**
 * The Home container
 */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Spinner } from "reactstrap";
import TokenCard from "../TokenCard";

/**
 * Home container used as app default landing page
 */
const TokenList = (props) => {
  const { collection } = props;
  const tokenIds = useSelector((state) => state.tokens.idsList);
  const loadingList = useSelector((state) => state.tokens.loadingList);

  if (loadingList)
    return (
      <div className="my-5 d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
    );

  return (
    <div className="nft-list">
      <Row>
        {tokenIds.map((tokenId) => (
          <Col
            key={`token-${tokenId}`}
            sm={collection ? 12 : 6}
            md={collection ? 6 : 4}
            lg={collection ? 4 : 3}
          >
            <TokenCard tokenId={tokenId} collection={collection} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

TokenList.propTypes = {
  collection: PropTypes.bool,
};

TokenList.defaultProps = {
  collection: false,
};

/**  define proptype for the 'translation' function  */
TokenList.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default TokenList;
